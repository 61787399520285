nav.navbar .navbar-collapse {
  justify-content: end;
}
.nav-link:focus,
.nav-link:focus-visible {
  outline: 0;
}
.nav-link .nav_item_inner {
  background-color: #b9deaf;
  color: #313237;
  padding: 22px 25px 24px 25px;
  border-radius: 23px;
  display: inline-block;
  line-height: 0;
  transition: 0.5s;
  font-size: 18px;
  font-weight: 400;
}
.nav-link .nav_item_inner:hover {
  background-color: #5fa540;
  color: #fff;
  transition: 0.5s;
}
nav.navbar .navbar-collapse .navbar-nav {
  gap: 15px;
  align-items: center;
}
.nav-link .nav_item_inner.active_nav {
  background-color: #5fa540;
  color: #fff;
}
.nav-link .nav_last_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  background-color: #d2e5cd;
  color: #5c5e63;
  border-radius: 23px;
  transition: 0.5s;
  font-size: 16px;
  padding: 8px 13px 8px 15px;
  font-weight: 500;
}
.header_nav_link .nav_last_btn .nav_last_btn_img {
  width: 16px;
}
.header_nav_link .nav_last_btn:hover img {
  filter: brightness(10);
  transition: 0.5s !important;
}
.nav-link .nav_last_btn:hover {
  background-color: #5fa540;
  color: #fff;
  transition: 0.5s;
}
.nav-link .nav_last_btn:hover svg {
  color: #fff !important;
  transition: 0.5s;
}
nav.navbar.navbar-light.bg-light {
  background-color: transparent !important;
  padding: 40px 0 15px 0 !important;
}
.search_field {
  background-color: #b9deaf;
  padding: 0.5rem;
  border-radius: 100px;
  line-height: 0;
  transition: 0.5s;
  font-size: 18px;
  font-weight: 400;
  position: relative;
}
.search_field input.search_button {
  background-color: transparent;
  border: none;
  color: #313237;
  font-size: 18px;
  font-weight: 400;
  line-height: 0;
  padding: 2px 35px 2px 25px;
}
.desktop_search_field {
  display: flex;
  align-items: center;
}

.search_field input.search_button::placeholder {
  color: #313237;
  font-size: 18px;
  font-weight: 400;
  line-height: 0;
}
.search_field input.search_button:focus-visible {
  outline: none;
}
.search_field svg.search_icon {
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: #434040;
}

.search_field:has(input:focus).search_field {
  background-color: #5fa540;
}
.search_field:has(input:focus).search_field input,
.search_field:has(input:focus).search_field input::placeholder,
.search_field:has(input:focus).search_field svg {
  color: #fff;
}

/* *******custom menu navigation************ */
.header_wrapper .header_nav_brand {
  width: 205px;
}
.header_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0 15px 0 !important;
}
.header_nav_link .header_nav_item_link {
  background-color: #b9deaf;
  color: #313237;
  padding: 22px 25px 24px 25px;
  border-radius: 23px;
  display: inline-block;
  line-height: 0;
  transition: 0.5s;
  font-size: 18px;
  font-weight: 400;
  user-select: none;
}
.header_wrapper .header_nav_link {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  gap: 25px;
}
.header_nav_link .header_nav_item_link:hover {
  background-color: #5fa540;
  color: #fff !important;
  transition: 0.5s;
}
.header_nav_item_link.active_nav {
  background-color: #5fa540 !important;
  color: #fff !important;
}
.header_nav_link .nav_last_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background-color: #d2e5cd;
  color: #5c5e63;
  border-radius: 23px;
  transition: 0.5s;
  font-size: 16px;
  padding: 8px 13px 8px 15px;
  font-weight: 500;
}
.header_nav_link .nav_last_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background-color: #d2e5cd;
  color: #5c5e63;
  border-radius: 23px;
  transition: 0.5s;
  font-size: 16px;
  padding: 8px 13px 8px 15px;
  font-weight: 500;
}
.header_nav_link .nav_last_btn:hover {
  background-color: #5fa540 !important;
  color: #fff !important;
  transition: 0.5s !important;
}
.header_nav_link .nav_last_btn:hover svg {
  color: #fff !important;
  transition: 0.5s;
}

/* *****mobile menu********* */
.mobile_header_warpper {
  padding: 40px 0 15px 0 !important;
}
.mobile_header_warpper .header_nav_brand {
  width: 215px;
}
.mobile_header_warpper .mobile_header_warpper_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile_header_warpper .mobile_header_warpper_inner .menu_icon button {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.mobile_header_warpper .header_nav_link {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.mobile_header_warpper .header_nav_link_main_inner {
  position: absolute;
  width: 100%;
  top: 10px;
  left: 0;
  right: 0;
  background-color: #313237;
  padding: 20px;
  border-radius: 15px;
  z-index: 9;
}
.mobile_header_warpper .header_nav_link_main {
  position: relative;
}
.mobile_header_warpper .header_nav_link .nav_last_btn {
  justify-content: center;
  gap: 10px;
}
/* *****mobile menu********* */

@media screen and (max-width: 1250px) and (min-width: 1023px) {
  .header_wrapper .header_nav_link {
    gap: 15px;
  }
  .header_wrapper .header_nav_brand {
    width: 260px !important;
  }
  .header_wrapper {
    gap: 25px;
  }
  .header_nav_link .header_nav_item_link {
    font-size: 20px;
    padding: 22px 18px 24px 18px;
  }
  .header_nav_link .search_field input.search_button {
    padding: 2px 40px 2px 15px;
    max-width: 215px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 200px) {
  .desktop_header_wrapper {
    display: none;
  }
}
