@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
body {
  font-family: "Poppins", sans-serif;
}
a {
  color: #5fa540;
  text-decoration: none;
}
a:hover {
  color: #686b71 !important;
}
img {
  max-width: 100%;
}

body {
  font-size: 15px;
}

.loader {
  margin: auto;
}

h1 {
  font-size: 30px !important;
  font-weight: 600;
  line-height: 1.3;
}

h2 {
  font-size: 28px !important;
  font-weight: 600;
  line-height: 1.3;
}

h3 {
  font-size: 24px !important;
  font-weight: 600;
  line-height: 1.3;
}

h4 {
  font-size: 22px !important;
  font-weight: 600;
  line-height: 1.3;
}
h5 {
  font-size: 18px !important;
  font-weight: 400;
  line-height: 1.3;
}

label {
  font-size: 14px !important;
  font-weight: 600;
  color: #0e393a;
  margin-bottom: 7px !important;
  text-transform: capitalize;
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
select {
  font-size: 14px !important;
  color: #404040 !important;
  font-weight: 400 !important;
  width: 100% !important;
  border-radius: 35px !important;
  padding: 15px 30px 15px 30px !important;
  margin-bottom: 20px !important;
  height: 55px !important;
  background-color: #ebebeb !important;
  border: none !important;
}
button {
  outline: 0;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
select:focus {
  color: unset !important;
  background-color: #ebebeb !important;
  border-color: unset !important;
  box-shadow: none !important;
}
.Toastify {
  position: relative;
  z-index: 99999;
}
.App {
  background-image: url("../Asstes/Images/body_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.middle_body {
  padding: 80px 0 120px 0;
}
.heading {
  text-align: left;
  margin-bottom: 50px;
  position: relative;
  padding-left: 20px;
}
.heading h1 {
  margin: 0;
}
.heading:before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: #5fa540;
}
button.navbar-toggler {
  border: none;
  border-radius: 0;
  padding-right: 0;
}
.navbar-toggler.collapsed span.navbar-toggler-icon {
  background-image: url("../Asstes/Images/hamburger.png");
}
.navbar-toggler span.navbar-toggler-icon {
  background-image: url("../Asstes/Images/close.png");
}
.navbar-toggler:focus {
  box-shadow: none;
}
.row > * {
  padding-right: calc(3rem * 0.5);
  padding-left: calc(3rem * 0.5);
}
.navbar .container {
  padding-right: calc(3rem * 0.5);
  padding-left: calc(3rem * 0.5);
}
/* global css end */

/* home page css start */
.home_pg .col-lg-4.col-md-6 {
  margin-bottom: 64px;
}
.tile_box {
  background-color: #fff;
  padding: 10px 10px 25px 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #8f8f8fb8;
  cursor: pointer;
  transition: 0.5s;
}
.tile_box:hover {
  background-color: #313237;
  transition: 0.5s;
}
.tile_box .img {
  height: 225px;
  border-radius: 15px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.tile_box .title {
  max-width: 238px;
  margin: 20px auto 10px auto;
  min-height: 75px;
}
.tile_box .title h2,
.tile_box:hover .title h3 {
  margin-bottom: 0;
  transition: 0.5s;
}
.tile_box:hover .title h2,
.tile_box:hover .title h3 {
  color: #fff;
  transition: 0.5s;
}
.tile_box .link_btn,
.link_btn {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 25px;
  background-color: #ddf1d7;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}
.tile_box:hover .link_btn {
  background-color: #fff;
  transition: 0.5s;
}
.tile_box .link_btn svg,
.link_btn svg {
  color: #71b35a;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.tile_box .link_btn a {
  display: inline-block;
  line-height: 0;
  text-align: center;
  margin-left: 1.5px;
}
.tile_box .link_btn.icon a {
  margin-left: 0;
}
.tile_box {
  height: 100%;
}
.services_pg .col-lg-4.col-md-6 {
  margin-bottom: 64px;
}
.ServicesList.tile_box .title {
  max-width: 270px;
  margin: 40px auto 15px auto;
}
.tile_box .text {
  max-width: 280px;
  margin: 0 auto;
}
.tile_box .text p {
  margin-bottom: 0;
  color: #686b71;
  transition: 0.5s;
}
.tile_box:hover .text p {
  color: #fff;
  transition: 0.5s;
}
/* home page css end */

/* category page css start */
.category_pg {
  margin-bottom: 24px;
}
.category_box {
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 10px;
  box-shadow: 0px 0px 10px 0px #8f8f8fb8;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  transition: 0.5s;
  cursor: pointer;
}
.multipule_category_box {
  height: 100%;
  padding: 0;
}
.category_pg .row .col-lg-4,
.subcategory_pg .row .col-lg-4 {
  margin-bottom: 35px;
}

.category_box:hover {
  background-color: #313237;
  transition: 0.5s;
}
.category_box:hover h4 {
  color: #fff;
  transition: 0.5s;
}
.cat_icon {
  width: 50px;
  height: 50px;
  flex: 0 0 20%;
  text-align: right;
}
.cat_title {
  flex: 0 0 65%;
}
.category_box .cat_title {
  padding: 0 20px;
}
.cat_title h4 {
  margin: 0;
}
.category_box img {
  transition: 0.5s;
}
.category_box:hover img {
  filter: brightness(0) invert(1);
  transition: 0.5s;
}
.category_box:hover .link_btn {
  background-color: #fff;
  transition: 0.5s;
}
/* category page end start */

/* DownloadLink sec css start */
.Download_box {
  background-color: #fff;
  padding: 0 10px;
  border-radius: 15px;
  margin-top: 31px;
  box-shadow: 0px 0px 10px 0px #8f8f8fb8;
}
.group_img {
  display: flex;
  padding: 30px 0 50px 0;
  justify-content: center;
  flex-wrap: wrap;
}
.Download_box .group_img img {
  height: 100px;
  width: 325px;
}
.group_img a {
  flex: 0 0 42%;
}
.group_img a img {
  cursor: pointer;
}
.Download_box h2 {
  font-size: 30px !important;
  color: #fff;
  padding: 20px 10px;
  max-width: 630px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  background-image: url(../Asstes/Images/bg_corner.png);
}
/* DownloadLink sec css end */

.navbar-nav a.nav-link:last-child {
  padding-right: 0;
}

/* Weather page css start */
.category_box.weather_box {
  display: block;
  text-align: left;
  padding: 25px 25px 15px 25px;
  position: relative;
  height: 100%;
  margin: 0;
  cursor: initial;
}
.category_box.weather_box h4 {
  margin-bottom: 5px;
}
.category_box.weather_box .cat_icon {
  position: absolute;
  top: 25px;
  right: 25px;
}
.category_box.weather_box p {
  transition: 0.5s;
  max-width: 235px;
}
.category_box.weather_box:hover p {
  color: #fff;
  transition: 0.5s;
}
.category_box.weather_box p sapn {
  color: #60aa47;
  display: block;
}
.category_box.weather_green_box {
  margin-top: 40px;
  background-color: #60aa47;
  transition: 0.5s;
  display: block;
  text-align: left;
  color: #fff;
  padding: 35px 25px;
  cursor: initial;
}
.category_box.weather_green_box:hover {
  background-color: #313237;
  transition: 0.5s;
}
.category_box.weather_green_box p {
  margin: 0;
  font-size: 15px;
}
.category_box.weather_green_box h4 {
  margin: 5px 0 10px 0;
  font-size: 35px !important;
}
.category_box.weather_green_box .currnt_icon {
  text-align: right;
}
.Weather_pg .col-lg-4.col-md-6 {
  margin-bottom: 40px;
}
/* Weather page css end */
/* Look About page start */
.about_look_aus p,
.team_australia_main p {
  text-align: left;
}
.about_look_aus ul li,
.team_australia_main ul li {
  text-align: start;
}
.about_look_aus h2,
.team_australia_main h2 {
  text-align: left;
}
.marketing_channel_main .marketing_channel_logo {
  width: 72px;
}
.marketing_channel_main {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 60%;
  margin: 35px 0 50px 0;
}
.bottom_contact_info {
  display: inline-block;
}
.tile_box .text p span {
  color: unset !important;
}
/* Look About page end */

/* business detail start */
.look_business_content_main {
  margin-bottom: 35px;
}
.look_business_content .bg_img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 10px;
  height: 275px;
  margin-bottom: 20px;
}
.calendar_main_section {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #cac8c8;
  border-radius: 10px;
  padding: 25px 25px 12px 25px;
  -webkit-user-select: none;
  user-select: none;
  font-family: "Lato", sans-serif;
}
.calendar_main_section_inner {
  width: 100%;
}
.calendar_section_open {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.calendar_section_open .open_head_title {
  max-width: 120px;
  min-width: 90px;
  text-transform: uppercase;
  color: #62a945;
  font-weight: 600 !important;
  text-align: left;
}
.calendar_main_section .head_title_openings {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}
.calendar_main_section .head_title_openings span {
  font-weight: 600;
}
.calendar_schedule_section {
  display: flex;
  border-bottom: 1px solid #c6c1c1;
  padding-bottom: 10px;
  padding-top: 18px;
  justify-content: space-between;
}
.schedule_open_title {
  max-width: 120px;
  min-width: 90px;
  text-align: left;
}
.schedule_detail_inner {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}
.calendar_schedule_section.schedule_all_days {
  border: none;
}
.look_business_content .business_info_para {
  text-align: left;
}
.look_business_content_main h1 {
  font-size: 25px !important;
  text-align: center;
  margin-bottom: 45px;
}
.look_black_btn {
  background-color: #303238;
  color: #fff;
  transition: 0.5s;
  border: none;
  box-shadow: none;
  padding: 10px 25px;
  border-radius: 5px;
}
.look_green_btn {
  background-color: #60aa47;
  color: #fff;
  transition: 0.5s;
  border: none;
  box-shadow: none;
  padding: 10px 25px;
  border-radius: 5px;
}
.look_black_btn:hover {
  background-color: #60aa47;
  transition: 0.5s;
}
.look_green_btn:hover {
  background-color: #303238;
  transition: 0.5s;
}
.look_business_content_main .promo_business_btn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}
.look_business_content .schedule_sheet_info {
  margin-top: 22px;
  text-align: left;
}
.look_business_content .schedule_sheet_info p {
  margin-bottom: 5px;
}
.look_business_content .schedule_sheet_info a {
  color: #333333;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
}
.look_business_content .schedule_sheet_info .special_offer {
  background: transparent;
  border: 1px solid #303238;
  color: #303238;
  transition: 0.5s;
  padding: 10px 17px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
}
.look_business_content .schedule_sheet_info .special_offer:hover {
  border: 1px solid #60aa47;
  color: #60aa47 !important;
  transition: 0.5s;
  background-color: transparent;
}
.schedule_sheet_bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}
.search_record_not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.record_not_found_inner {
  background-color: #fff;
  padding: 20px 100px;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.record_not_found_inner h2 {
  margin-bottom: 0;
}
.business-book-now .modal-footer {
  border: 0;
  flex-wrap: nowrap;
}
.business-book-now .modal-footer button,
.business-book-now .modal-footer a {
  width: 100%;
}
.business-book-now .modal-header {
  border: 0;
  padding: 0;
}
.business-modal .btn-close {
  margin: 0 !important;
  padding: 0 !important;
  height: 20px;
  width: 20px;
  line-height: 20px;
  background-color: #62a945;
  opacity: 1;
  position: absolute;
  right: -10px;
  top: -10px;
  text-align: center;
  border: none;
  font-size: 10px;
}
.business-book-now .modal-footer button {
  background-color: #62a945;
  border: 0;
}
.business-book-now .modal-footer button:hover {
  background-color: #303238;
  transition: 0.5s;
}
.business-book-now .modal-footer a {
  background-color: #303238;
  border: 0;
}
.business-book-now .modal-footer a:hover {
  background-color: #62a945;
  transition: 0.5s;
  color: #fff !important;
  border: 0;
}
.business-book-now.phone-modal .modal-footer button {
  text-transform: capitalize;
  flex: 1.1 1;
  width: 100%;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  color: #fff;
}
.business-modal .btn-close:focus {
  box-shadow: none;
}
.modal_special_offer .modal-header {
  padding: 0;
  border: 0;
}
.modal_special_offer .modal-body {
  text-align: center;
  font-size: 14px;
  padding: 40px 0;
  max-width: 385px;
  margin: auto;
}
/* business detail  end*/
.map_frame_section {
  height: 500px;
  width: 100%;
}

/* responsive start */
@media screen and (max-width: 1250px) and (min-width: 1023px) {
  .Download_box .group_img {
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 1024px) and (min-width: 991px) {
  .category_box.weather_box p {
    max-width: 180px;
  }
  .nav-link .nav_item_inner {
    display: block;
  }
  .nav-link .nav_last_btn {
    justify-content: center;
  }
  .navbar-nav {
    background-color: #313237;
    padding: 20px;
    border-radius: 15px;
  }
  .nav-link .nav_item_inner {
    display: block;
  }
  .navbar-nav a.nav-link {
    width: 100%;
  }
  div#basic-navbar-nav {
    position: absolute;
    z-index: 9;
    top: 70px;
    left: 0;
    right: 0;
  }
  nav.navbar.navbar-light.bg-light .container {
    position: relative;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .look_business_content_main .promo_business_btn button {
    font-size: 12px;
  }
  .Download_box .group_img {
    justify-content: space-evenly;
  }
  .marketing_channel_main {
    max-width: 100%;
  }
  .Download_box h2 {
    font-size: 30px !important;
  }
  .nav-link .nav_item_inner {
    display: block;
  }
  .nav-link .nav_last_btn {
    justify-content: center;
  }
  .navbar-nav {
    background-color: #313237;
    padding: 20px;
    border-radius: 15px;
  }
  .nav-link .nav_item_inner {
    display: block;
  }
  .navbar-nav a.nav-link {
    width: 100%;
  }
  div#basic-navbar-nav {
    position: absolute;
    z-index: 9;
    top: 70px;
    left: 0;
    right: 0;
  }
  nav.navbar.navbar-light.bg-light .container {
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .Download_box .group_img a {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px;
  }
  .Download_box .group_img {
    gap: 0;
  }
  .Download_box .group_img img {
    height: 70px;
    width: 100%;
  }
  .record_not_found_inner {
    padding: 20px 30px;
  }
  input.search_button.search_button_loader {
    padding: 2px 30px 2px 5px;
  }
  .mobile_header_warpper .header_nav_brand {
    width: 150px;
  }
  .look_business_content_main .promo_business_btn button {
    font-size: 12px;
    padding: 10px 15px;
    margin-bottom: 20px;
  }
  .marketing_channel_main {
    max-width: 100%;
  }
  .middle_body {
    padding: 50px 15px 80px 15px;
  }
  .Download_box {
    margin-top: 30px;
  }
  .footer_menu ul li {
    padding: 5px 25px;
  }
  .footer_menu ul li:nth-child(2):before,
  .footer_menu ul li:nth-child(3):before {
    display: none;
  }
  .footer_menu {
    padding: 20px 0;
  }
  .footer_menu ul li:first-child {
    padding-left: 25px;
  }
  .footer_menu ul li:nth-child(4) {
    padding-left: 0;
  }
  .Download_box h2 {
    font-size: 17px !important;
  }
  .group_img {
    gap: 30px;
  }
  .group_img a {
    flex: 0 0 98%;
  }
  .navbar > .container {
    flex-wrap: nowrap !important;
  }
  .Download_box h2 {
    background-image: url(../Asstes/Images/bg_corner_mobile.png);
  }

  .nav-link .nav_item_inner {
    display: block;
  }
  .nav-link .nav_last_btn {
    justify-content: center;
  }
  .navbar-nav {
    background-color: #313237;
    padding: 20px;
    border-radius: 15px;
  }
  .nav-link .nav_item_inner {
    display: block;
  }
  .navbar-nav a.nav-link {
    width: 100%;
  }
  div#basic-navbar-nav {
    position: absolute;
    z-index: 9;
    top: 70px;
    left: 0;
    right: 0;
  }
  nav.navbar.navbar-light.bg-light .container {
    position: relative;
  }
  .search_field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 25px 9px 25px;
  }
}

/* responsive end */
