.footer {
  background-color: #313237;
  color: #fff;
  padding: 85px 0 55px 0;
}
.footer_menu {
  background-color: #26282c;
  padding: 17px 0;
  margin: 45px 0 45px 0;
}
.footer_menu ul {
  padding: 0;
  margin: 0;
}
.footer_menu ul li {
  list-style: none;
  display: inline-block;
  padding: 0px 25px;
  position: relative;
}
.footer_menu ul li:last-child {
  padding-right: 0;
}
.footer_menu ul li:first-child {
  padding-left: 0;
}
.footer_menu ul li:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 10px;
  background-color: #fff;
}
.footer_menu ul li:last-child:before {
  display: none;
}
.footer_menu ul li {
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  transition: 0.5s;
  opacity: 80%;
}
.footer_menu ul li:hover {
  color: #5fa540 !important;
  transition: 0.5s;
}

.copyrights p {
  margin: 0;
  color: #ababab;
  font-size: 18px;
}
.footer_menu li.footer_list a {
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  transition: 0.5s;
  opacity: 80%;
}
.footer_menu li.footer_list a:hover {
  color: #5fa540 !important;
  transition: 0.5s;
}
